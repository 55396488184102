/* General Styling */
.about-page {
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.6;
  margin: 100px auto;
  padding: 0 20px;
  max-width: 4100px;
}

h1, h2, h3, h4 {
  color: #0056b3;
}

p {
  color: #555;
  font-size: 16px;
  margin-bottom: 15px;
}

/* Header Section */
.about-header {
  text-align: center;
  padding: 50px 20px;
  background-color: #f4f4f9;
}

.team-image {
  height: 220px;
  width: 220px;
  border: 1px solid black;
}

.about-header h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.about-header p {
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
}

.header-img img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
}

/* Mission Section */
.mission-statement {
  padding: 40px 20px;
  background-color: #fff;
  text-align: center;
}

.mission-statement h2 {
  font-size: 30px;
}

.mission-statement p {
  font-size: 18px;
  max-width: 700px;
  margin: 20px auto;
}

/* Vision & Values Section */
.vision-values {
  padding: 40px 20px;
  background-color: #f4f4f9;
  text-align: center;
}

.vision-values h2 {
  font-size: 30px;
  margin-bottom: 30px;
}

.values-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.value-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 30%;
  margin-bottom: 20px;
}

.value-card h3 {
  margin-bottom: 15px;
  font-size: 22px;
}

.value-card p {
  font-size: 16px;
}

/* Responsive Vision & Values Cards */
@media (max-width: 768px) {
  .value-card {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .value-card {
    width: 100%;
  }
}

/* Timeline Section */
.timeline-section {
  padding: 40px 20px;
  background-color: #fff;
}

.timeline-section h2 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
}

.timeline {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.timeline-item {
  background-color: #f4f4f9;
  border-radius: 8px;
  padding: 20px;
  width: 30%;
  text-align: center;
  margin-bottom: 20px;
}

.timeline-item h4 {
  font-size: 22px;
  margin-bottom: 10px;
}

.timeline-item p {
  font-size: 16px;
}

@media (max-width: 768px) {
  .timeline-item {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .timeline-item {
    width: 100%;
  }
}

/* Team Section */
.team-section {
  padding: 40px 20px;
  background-color: #f4f4f9;
}

.team-section h2 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
}

.team-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.team-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.team-card img {
  border-radius: 50%;
  margin-bottom: 15px;
}

.team-card h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.team-card p {
  font-size: 16px;
  margin-bottom: 10px;
}

.team-bio {
  font-size: 14px;
  color: #777;
}

@media (max-width: 768px) {
  .team-card {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .team-card {
    width: 100%;
  }
}

/* How We Work Section */
.how-we-work {
  padding: 40px 20px;
  background-color: #fff;
  text-align: center;
}

.how-we-work h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.how-we-work p {
  max-width: 700px;
  margin: 20px auto;
  font-size: 18px;
}

.work-img img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
}

.call-to-action {
  text-align: center;
  background-color: #0056b3;
  color: white;
  padding: 40px;
  max-width: 1200px;
  margin: 10px auto;
  border-radius: 10px;
}

.call-to-action h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #f4f4f9;
}

.call-to-action p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #ffca28;
}

.cta-button {
  background-color: #ffca28;
  color: #0056b3;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #ffb300;
}