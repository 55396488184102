.employee-engagement {
    padding: 2rem;
    background-color: #ffffff;
    color: #333;
    max-width: 1400px;
    margin: 5px auto;
    margin-top: 100px;
}

.header-section {
    text-align: center;
    margin-bottom: 2rem;
}

.header-section h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.header-section p {
    font-size: 1.2rem;
    color: #555;
}

.overview-section {
    padding: 3rem 2rem;
    /* Increased padding for better spacing */
    background-color: #ffffff;
    /* White background for clarity */
    border-radius: 8px;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    max-width: 1200px;
    /* Maximum width for better readability */
    margin: 2rem auto;
    /* Centered with margin */
}

.overview-section h2 {
    text-align: center;
    /* Centered heading */
    font-size: 2.5rem;
    /* Larger font size for emphasis */
    color: #0056b3;
    /* Primary color for headings */
    margin-bottom: 1rem;
    /* Space below heading */
}

.overview-section p {
    font-size: 1.2rem;
    /* Slightly larger font for better readability */
    line-height: 1.6;
    /* Increased line height for easier reading */
    color: #333;
    /* Darker text for better contrast */
    text-align: center;
    /* Center the text */
    margin-bottom: 2rem;
    /* Space below paragraph */
}

.features-section {
    margin: 3rem 0;
}

.features-section h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.features-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.feature-item {
    flex: 1 1 200px;
    margin: 1rem;
    padding: 1.5rem;
    border: 1px solid #0056b3;
    border-radius: 8px;
    background-color: #f1f1f1;
    text-align: center;
}

.benefits-section {
    margin: 3rem 0;
}

.benefits-section h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.benefits-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.benefit-card {
    flex: 1 1 240px;
    /* Adjusts to fit three to four cards in a row */
    margin: 1rem;
    padding: 1.5rem;
    border: 1px solid #0056b3;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.benefit-card h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #0056b3;
}

.benefit-card p {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
}

.benefit-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.call-to-action {
    text-align: center;
    margin-top: 3rem;
}

.call-to-action h2 {
    font-size: 2rem;
    color: #f7f9fc;
}

.call-to-action p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.cta-button {
    background-color: #f7f9fc;
    color: #0056b3;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #003d7a;
}