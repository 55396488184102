.inclusivity-communication {
    max-width: 1400px;
    margin: 100px auto;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;

    .header-section {
        color: #0056b3;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 30px;
    }

    .header-section h1 {
        font-size: 2.5em;
        margin-bottom: 10px;
    }

    .header-section p {
        font-size: 1.2em;
    }

    .overview-section {
        margin-bottom: 30px;
    }

    .overview-section h2 {
        font-size: 2em;
        margin-bottom: 10px;
    }

    .overview-section p {
        font-size: 1.1em;
        line-height: 1.6;
        color: #333;
    }

    .features-section {
        margin-bottom: 30px;
    }

    .features-section h2 {
        font-size: 2em;
        margin-bottom: 20px;
    }

    .features-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .feature-item {
        background-color: #f8f8f8;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        text-align: left;
    }

    .feature-item h3 {
        font-size: 1.8em;
        color: #0056b3;
        margin-bottom: 10px;
    }

    .feature-item p {
        font-size: 1.1em;
        color: #333;
    }

    .benefits-section {
        margin-bottom: 30px;
    }

    .benefits-section h2 {
        font-size: 2em;
        margin-bottom: 20px;
    }

    .benefits-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .benefit-card {
        flex: 1 1 240px;
        /* Adjusts to fit three to four cards in a row */
        margin: 1rem;
        padding: 1.5rem;
        border: 1px solid #0056b3;
        border-radius: 8px;
        background-color: #f9f9f9;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        text-align: center;
        transition: transform 0.3s, box-shadow 0.3s;
    }

    .benefit-card h3 {
        font-size: 1.8em;
        color: #0056b3;
        margin-bottom: 10px;
    }

    .benefit-card p {
        font-size: 1.1em;
        line-height: 1.5;
        color: #333;
    }

    .call-to-action {
        margin-top: 50px;
    }

    .call-to-action h2 {
        font-size: 2.2em;
        margin-bottom: 20px;
    }

    .call-to-action p {
        font-size: 1.2em;
        margin-bottom: 30px;
    }

    .cta-button {
        background-color: #f7f9fc;
        color: #0056b3;
        border: none;
        padding: 15px 30px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1.2em;
        transition: background-color 0.3s;
    }

    .cta-button:hover {
        background-color: #003d7a;
    }

}