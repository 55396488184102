/* General Page Styling */
.content-library-page {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0px 20px;
}

/* Header Section */
.header {
    text-align: center;
    background-color: #0056b3;
    color: white;
    padding: 20px;
    border-bottom: 2px solid #ddd;
}

.header h1 {
    font-size: 2.5em;
    color: white;
    margin-bottom: 10px;
}

.header p {
    font-size: 1.2em;
    color: azure;
}

/* Filter and Search Section */
.filter-search-section {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    gap: 10px;
}

.search-bar {
    width: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
}

.filter-dropdown {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
}

.filter-button {
    padding: 10px 20px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.filter-button:hover {
    background-color: #004494;
}

/* Content Grid */
.content-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin: 40px;
}

.content-card {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.content-card:hover {
    transform: scale(1.05);
}

.content-card h3 {
    color: #0056b3;
    font-size: 1.5em;
    margin-bottom: 10px;
}

.content-card p {
    color: #666;
    font-size: 1em;
}

/* Content Section */
.content-section {
    margin: 50px 40px;
}

.resource-item {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.resource-item h3 {
    color: #0056b3;
    font-size: 1.8em;
}

.resource-item p {
    color: #666;
    font-size: 1.2em;
}

.read-more {
    color: #0056b3;
    text-decoration: none;
    font-weight: bold;
}

.read-more:hover {
    text-decoration: underline;
}

/* Footer Section */
.footer {
    text-align: center;
    margin: 40px 0;
    padding: 20px;
}

.footer .heart {
    color: #e74c3c;
}