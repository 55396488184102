/* Hero Container */
.hero-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80%;
  background: #f0f8ff; /* Gradient from #0056b3 to a darker shade */
  color: #0056b3;
  text-align: left;
  padding: 0 5px;
  margin-top: 62.5px;
  flex-wrap: wrap-reverse;
}

/* Hero Content 1 */
.hero-content1 {
  max-width: 45%;
  padding: 50px;
}

.hero-content1 h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-content1 p {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

/* Buttons */
.request-demo-btn, .take-a-tour-btn {
  padding: 15px 30px;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.request-demo-btn {
  background-color: #0056b3;
  color: #fff;
  border: 1px solid #fff;
}

.take-a-tour-btn {
  background-color: #fff;
  color: #0056b3;
  border: 1px solid #0056b3;
  margin-left: 20px;
}

.take-a-tour-btn:hover {
  background-color: #a1c6ec;
}

.request-demo-btn:hover {
  background-color: #4096f2;
}

/* Hero Content 2 */
.hero-content2 {
  width: 50%;
  padding-top: 5px;
  height: 100%;
}

.hero-content2 img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

/* Action Container */
.action-container {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hero-container {
    flex-direction: column;
    padding: 20px;
    margin-top: 30px;
  }

  .hero-content1, .hero-content2 {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    text-align: center;
  }

  .hero-content1 h1 {
    font-size: 2rem;
  }

  .hero-content1 p {
    font-size: 1.1rem;
  }

  .action-container {
    justify-content: center;
    width: 100%;
  }

  .request-demo-btn, .take-a-tour-btn {
    padding: 12px 25px;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
  }

  .hero-content1 h1 {
    font-size: 1.8rem;
  }

  .hero-content1 p {
    font-size: 1rem;
  }

  .action-container {
    flex-direction: column;
    gap: 10px;
  }

  .request-demo-btn, .take-a-tour-btn {
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .hero-content1 h1 {
    font-size: 1.5rem;
  }

  .hero-content1 p {
    font-size: 0.9rem;
  }

  .action-container {
    width: 100%;
  }

  .request-demo-btn, .take-a-tour-btn {
    padding: 10px 20px;
    font-size: 0.85rem;
  }
}
