/* HelpCenter Page Styles */
body {
    background-color: #f0f8ff;
}
.help-center-container {
    max-width: 800px;
    margin: 80px auto;
    padding: 20px;
  }
  
  .help-center-title {
    font-size: 2.5rem;
    text-align: center;
    color: #0056b3;
    margin-bottom: 20px;
  }
  
  .help-center-description {
    text-align: center;
    color: #666;
    margin-bottom: 40px;
    font-size: 1.2rem;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .faq-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .faq-item.expanded {
    background-color: #f4f6f8;
  }
  
  .faq-question {
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    color: #333;
  }
  
  .faq-answer {
    margin-top: 10px;
    font-size: 1rem;
    color: #555;
  }
  
  .faq-toggle-icon {
    font-size: 1.5rem;
    color: #0056b3;
  }
  
  .faq-item:hover {
    background-color: #f1f3f5;
  }
  