.why-culturelinkr {
    padding: 4rem 0;
    background-color: #f0f5fa;
  }
  
  .why-culturelinkr .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
  
  .why-culturelinkr h2 {
    text-align: center;
    font-size: 2.5rem;
    color: #0056b3;
    margin-bottom: 3rem;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .card {
    background-color: white;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    padding: 2rem;
    width: calc(50% - 1rem);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  .card h3 {
    font-size: 1.5rem;
    color: #0056b3;
    margin-bottom: 1rem;
  }
  
  .card p {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #333;
  }
  
  .card strong {
    color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .card {
      width: 100%;
    }
  }
  