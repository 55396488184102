.why-choose-us {
    padding: 2rem;
    margin-top: 80px;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .header-section {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .header-section h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  .header-section p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .benefits-section {
    margin: 3rem 0;
  }
  
  .benefits-section h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .benefits-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
  
  .benefit-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: center;
    width: 300px;
  }
  
  .benefit-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .testimonials-section {
    margin: 3rem 0;
  }
  
  .testimonials-section h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  blockquote {
    background-color: #e9f5ff;
    border-left: 5px solid #0056b3;
    padding: 1rem;
    width: 80%;
    font-style: italic;
  }
  
  .cita {
    margin-top: 0.5rem;
    font-weight: bold;
  }
  
  .cta-section {
    text-align: center;
    margin: 3rem 0;
  }
  
  .cta-section h2 {
    font-size: 2rem;
  }
  
  .cta-button {
    background-color: #0056b3;
    color: #fff;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
  }
  
  .cta-button:hover {
    background-color: #004499;
  }
  