/* 404Page Styles */
.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f6f8;
    padding: 20px;
}

.not-found-content {
    text-align: center;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 500px;
}

.error-title {
    font-size: 6rem;
    color: #0056b3;
    margin: 0;
    font-weight: bold;
}

.error-subtitle {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
}

.error-message {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
}

.home-button {
    display: inline-block;
    padding: 12px 25px;
    background-color: #0056b3;
    color: #ffffff;
    border-radius: 6px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.home-button:hover {
    background-color: #003d80;
}
