.coming-soon-main-container {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    /* Light background for contrast */
    color: #0056b3;
    /* Primary color from CultureLinkr theme */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;

}

.coming-soon-container {
    max-width: 600px;
    padding: 20px;
    border: 2px solid #0056b3;
    /* Add border with primary color */
    border-radius: 10px;
    background-color: #ffffff;
    /* White background for the content */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
}

.coming-soon-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.coming-soon-message {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.contact-info {
    margin-top: 20px;
}

.contact-link {
    display: inline-block;
    margin: 10px;
    text-decoration: none;
    color: #ffffff;
    background-color: #0056b3;
    /* Primary color */
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.contact-link:hover {
    background-color: #004080;
    /* Darker shade on hover */
}