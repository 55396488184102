.footer {
  background-color: #0056b3;
  color: white;
  padding: 4rem 2rem;
  font-family: "Arial", sans-serif;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-section {
  flex: 1 1 200px;
  margin-bottom: 2rem;
}

.footer-section h4 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: aliceblue;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: #f9f9f9;
}

.social-media {
  justify-content: center;
  display: flex;
  gap: 1rem;
}

.social-media a {
  color: white;
  font-size: 1.25rem;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: #f9f9f9;
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  color: #f9f9f9;
}

.footer-bottom p {
  font-size: 0.9rem;
  color: #f9f9f9;
}

.heart {
  color: #e74c3c;
  font-size: 22px;
}

.footer-love {
  margin: 0;
  font-family: Arial, sans-serif;
  color: #f9f9f9;
  text-align: center;
}

@media (max-width: 768px) {
  .footer-grid {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 1.5rem;
  }
}
