.pricing-page {
    max-width: 1200px;
    margin: 100px auto;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
}

.page-title {
    font-size: 2.5em;
    color: #0056b3;
}

.pricing-plans {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}

.benefits-list {
    min-height: 180px;
    text-align: left;
    font-size: 10px;
    list-style-type: disc;
}

.pricing-card {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    width: 40%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;

    h2 {
        font-size: 26px;
        color: #0056b3;
        font-weight: 600;
    }
}

.page-sub-title {
    margin-bottom: 50px;
}

.pricing-card:hover {
    transform: scale(1.05);
}

h2 {
    color: #333;
}

.original-price {
    font-size: 1.2em;
    margin: 15px 0;
}

.discounted-price {
    color: #e74c3c;
    /* Red for discounted price */
    font-weight: bold;
    font-size: 1.4em;
}

.price-cut {
    text-decoration: line-through;
    color: #aaa;
    /* Light grey for original price */
    margin-left: 10px;
}

.cta-button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #004494;
}

.comparison-button {
    margin-top: 30px;
    padding: 10px 20px;
    background-color: #2ecc71;
    /* Green for compare button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.close-modal {
    background-color: #e74c3c;
    /* Red for close button */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #0056b3;
    color: white;
}

.header {
    background-color: #0056b3;
    /* Adjust color to your theme */
    color: white;
    padding: 20px;
    margin-top: 100px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 8px;
}

.header h1 {
    font-size: 24px;
}

.header p {
    font-size: 16px;
}

.faqs-section {
    background: #fff;
    padding: 40px 50px;
    margin: 100px 10px 10px 10px;
}

.faqs-section h2 {
    text-align: center;
    color: #0056b3;
    font-size: 24px;
    margin-bottom: 20px;
}

.faq-item {
    text-align: left;
    margin-bottom: 20px;
}

.faq-item h3 {
    font-size: 1.2rem;
    color: #333;
}

.faq-item p {
    font-size: 1rem;
    color: #666;
}