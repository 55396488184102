/* Contact Us Styles */
.contact-us-container {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    margin: 80px auto;
    font-family: Arial, sans-serif;
}

.contact-title {
    color: #0056b3;
    font-size: 2.5rem;
    margin-bottom: 10px;
    text-align: center;
}

.contact-description {
    font-size: 1.1rem;
    margin-bottom: 30px;
    color: #333;
    text-align: center;
}

.contact-info {
    display: flex;
    flex-direction: column;
}

.info-item {
    margin: 15px 0;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    background-color: #f9f9f9;
    transition: box-shadow 0.3s ease;
}

.info-item:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.info-item h2 {
    color: #0056b3;
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.contact-link {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
}

.contact-link:hover {
    text-decoration: underline;
}

.social-links {
    display: flex;
    justify-content: flex-start;
    margin-top: 25px;
    flex-wrap: wrap;
}

.social-icon {
    margin: 0 10px;
    color: #0056b3;
    transition: transform 0.3s ease;
}

.social-icon:hover {
    transform: scale(1.1);
}
