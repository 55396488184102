.predictive-analysis-page {
    padding: 2rem;
    margin-top: 100px;
}

.predictive-analysis {
    padding: 3rem 2rem;
    /* Increased padding for better spacing */
    background-color: #ffffff;
    /* White background for clarity */
    border-radius: 8px;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    max-width: 1200px;
    /* Maximum width for better readability */
    margin: 2rem auto;
    /* Centered with margin */
}

.predictive-analysis h2 {
    text-align: center;
    /* Centered heading */
    font-size: 2.5rem;
    /* Larger font size for emphasis */
    color: #0056b3;
    /* Primary color for headings */
    margin-bottom: 1rem;
    /* Space below heading */
}

.predictive-analysis p {
    font-size: 1.2rem;
    /* Slightly larger font for better readability */
    line-height: 1.6;
    /* Increased line height for easier reading */
    color: #333;
    /* Darker text for better contrast */
    text-align: center;
    /* Center the text */
    margin-bottom: 2rem;
    /* Space below paragraph */
}

.predictive-image {
    text-align: center;
    /* Center the image */
}

.predictive-image img {
    max-width: 100%;
    /* Responsive image */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 5px;
    /* Slightly rounded corners for the image */
}


.predictive-header {
    text-align: center;
    margin-bottom: 3rem;
}

.predictive-header h1 {
    font-size: 2.5rem;
    color: #0056b3;
}

.predictive-header p {
    font-size: 1.2rem;
    color: #555;
}

.benefits-section {
    padding: 2rem;
    background-color: #f9f9f9;
    /* Light background for contrast */
}

.benefits-section h2 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2rem;
}

.benefits-section p {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.2rem;
}

.benefits-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* Center the cards */
    gap: 1rem;
    /* Space between cards */
}

.benefit-card {
    background-color: white;
    /* Card background */
    border: 1px solid #ddd;
    /* Card border */
    border-radius: 8px;
    /* Rounded corners */
    padding: 1.5rem;
    width: 300px;
    /* Fixed width for cards */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    transition: transform 0.3s;
    /* Smooth scaling on hover */
}

.benefit-card h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.benefit-card p {
    font-size: 1rem;
    color: #555;
    /* Slightly darker text for better readability */
}

.benefit-card:hover {
    transform: scale(1.05);
    /* Scale effect on hover */
}

.cta-button {
    display: block;
    margin: 2rem auto;
    /* Center button */
    padding: 1rem 2rem;
    background-color: #0056b3;
    /* Primary button color */
    color: white;
    /* Button text color */
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s;
    /* Smooth background color change */
}

.cta-button:hover {
    background-color: #004494;
    /* Darker shade on hover */
}

.how-it-works {
    margin: 3rem 0;
    text-align: center;
}

.how-it-works h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #0056b3;
}

.flowchart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flowchart-node {
    background-color: #f7f9fc;
    border: 1px solid #e1e4e8;
    border-radius: 8px;
    padding: 1.5rem;
    margin: 0.5rem 0;
    width: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.flowchart-arrow {
    font-size: 2rem;
    color: #0056b3;
    margin: 0.5rem 0;
}


.benefits-list {
    list-style-type: none;
    padding-left: 0;
}

.benefits-list li {
    font-size: 1.1rem;
    margin: 0.5rem 0;
}

.call-to-action {
    text-align: center;
    margin-top: 3rem;
}

.call-to-action h2 {
    font-size: 2rem;
    color: #f7f9fc;
}

.call-to-action p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.cta-button {
    background-color: #f7f9fc;
    color: #0056b3;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #003d7a;
}