.event-page {
    max-width: 1400px;
    margin: 80px auto;
    /* padding: 20px; */
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .header {
    background-color: #0056b3;
    color: white;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  
  .header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .header p {
    font-size: 1.2em;
  }
  
  .events-section {
    margin-top: 30px;
  }
  
  .event-card {
    background-color: #f8f8f8;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .event-card h3 {
    font-size: 1.8em;
    color: #0056b3;
    margin-bottom: 10px;
  }
  
  .event-card p {
    font-size: 1.1em;
    color: #333;
  }
  
  .no-events {
    font-size: 1.5em;
    color: #e74c3c;
    /* Red for no events available */
    margin-top: 50px;
  }
  