.features-container {
    padding: 10px 0px;
    margin: 0px 10px;
    border-top: 1px solid black;
    background: #ffffff; /* Gradient background for consistency */
    color: #fff;
  }
  
  .features-container h2 {
    font-size: 1rem;
    color: #000000b8;
    margin-bottom: 10px;
    margin-left: 50px;
  }

  .features-container h1 {
    font-size: 2.2rem;
    color: #000000;
    margin-left: 50px;
    width: 45%;
    font-weight: 800;
  }
  
  .features-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: center;
    gap: 20px;
  }
  
  .feature-card {
    background-color: #f0f8ff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin-bottom: 10px;
    width: 500px;
    height: 450px;
    color: #004080;
    text-align: center;
    img {
      width: 90%;
      height: 70%;
      margin: 10px 10px 20px 10px;
    }
  }
  
  .feature-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .feature-card p {
    font-size: 1rem;
    color: #6c757d;
  }
  