.terms-of-service {
    padding: 20px 0px 0px 0px;
    background: #f8f8f8;
    color: #333;
  }
  
  /* Header Section */
  .terms-header {
    text-align: center;
    padding: 100px 20px;
    background: linear-gradient(135deg, #0056b3, #0091ea);
    color: #fff;
    margin-bottom: 30px;
  }
  
  .terms-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .terms-header p {
    font-size: 1.2rem;
  }
  
  /* Content Sections */
  .terms-section {
    background: #fff;
    padding: 30px 20px;
    margin: 15px 35px 35px 35px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .terms-section h2 {
    font-size: 1.8rem;
    color: #0056b3;
    margin-bottom: 15px;
  }
  
  .terms-section p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
  }
  