/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Navbar Styles */
.navbar {
  background: #0056b3;
  color: #fff;
  padding: 10px 10px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.navbar-title {
  cursor: pointer;
}

.navbar.scrolled {
  background: #fff; /* Background color when scrolled */
  color: #0056b3; /* Text color when scrolled */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.navbar-logo img {
  height: 40px;
  margin-right: 10px;
  width: 40px;
}

/* Navbar Menu */
.navbar-menu {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  position: relative; /* To position dropdowns */
}

.navbar-menu a {
  text-decoration: none;
  font-size: 1rem;
  color: inherit; /* Inherit color from navbar */
  transition: color 0.3s ease;
  padding: 10px;
}

.navbar-menu a:hover {
  color: #f0f0f0;
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 110%;
  left: 0;
  background-color: #ffffff;
  padding: 10px 0;
  display: none;
  border-radius: 0px 0px 5px 5px;
  min-width: 150px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.flipped {
  transform: rotate(180deg); /* Flip arrow on hover */
}

.navbar-item:hover .dropdown-menu {
  display: block; /* Show dropdown on hover */
}

.dropdown-menu li {
  padding: 0;
}

.dropdown-menu a {
  display: block;
  padding: 10px 20px;
  color: #0056b3;
  white-space: nowrap;
  font-size: 0.9rem;
  text-align: left;
}

.dropdown-menu a:hover {
  background-color: #004080; /* Darker shade for hover effect */
}

/* CTA Button */
.navbar-cta-button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.arrow {
  display: inline-block; /* Make it inline-block to apply transform */
  font-size: 10px;
  margin-left: 10px; /* Space between title and arrow */
  transition: transform 0.3s ease; /* Smooth transition for flipping */
}

.navbar-cta-button:hover {
  background-color: #004080;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar-menu {
    display: none; /* Hide menu on small screens */
  }

  .navbar-cta-button {
    display: block;
  }
}
