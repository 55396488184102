.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top */
  }
  
  .modal-content {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 1200px;
    position: relative; /* To position the close button */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5em;
    cursor: pointer;
    color: #aaa; /* Light grey for the close button */
  }
  
  .close:hover {
    color: #000; /* Darker color on hover */
  }
  