.enterprise-pricing-page {
  max-width: 1200px;
  margin: 100px auto;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333;
}

.enterprise-pricing-page {

  .page-title {
    font-size: 2.5em;
    color: #0056b3;
    margin-bottom: 40px;
  }

  .page-sub-title {
    font-size: 1.5em;
    color: #666;
    margin-bottom: 50px;
  }

  .pricing-plans {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    gap: 30px;
    flex-wrap: wrap;
  }

  .benefits-list {
    min-height: 400px;
    text-align: left;
    font-size: 10px;
    list-style-type: disc;
  }

  .pricing-card {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 30px;
    width: 30%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
  }

  .pricing-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }

  .pricing-card h2 {
    height: 2.8em;
    font-size: 1.8em;
    color: #0056b3;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .original-price {
    font-size: 1.2em;
    margin: 20px 0;
    color: #555;
  }

  .discounted-price {
    color: #e74c3c;
    font-weight: bold;
    font-size: 1.6em;
  }

  .price-cut {
    text-decoration: line-through;
    color: #aaa;
    margin-left: 10px;
  }

  .cta-button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1em;
    transition: background-color 0.3s;
    margin-top: 20px;
  }

  .cta-button:hover {
    background-color: #004494;
  }

  .comparison-button {
    margin-top: 30px;
    padding: 10px 20px;
    background-color: #2ecc71;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }

  .close-modal {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-size: 1em;
  }

  th {
    background-color: #0056b3;
    color: white;
    font-size: 1.1em;
  }

  .header {
    background-color: #0056b3;
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .header h1 {
    font-size: 2em;
    margin: 0;
  }

  .header p {
    font-size: 1.1em;
    margin: 10px 0 0;
  }

  .faqs-section {
    background-color: #fff;
    padding: 40px;
    margin-top: 100px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .faqs-section h2 {
    text-align: center;
    color: #0056b3;
    font-size: 2em;
    margin-bottom: 30px;
  }

  .faq-item {
    text-align: left;
    margin-bottom: 25px;
  }

  .faq-item h3 {
    font-size: 1.3em;
    color: #333;
  }

  .faq-item p {
    font-size: 1.1em;
    color: #666;
  }

}